import React from "react";
import Stack from "@mui/material/Stack";
import { getClassNameActive } from "../config";
import MenuWithSubItem from "./menu-with-sub-item";
import Link from "next/link";
import WrapperLinkHover from "@src/components/WrapperLinkHover";
const Menu = ({
  menuConfigList,
  currentPath,
  onRedirect
}) => {
  return <Stack alignItems="center" justifyContent="space-between" sx={{
    width: "100%",
    height: "100%",
    maxWidth: 840,
    padding: "0 16px 0px 32px",
    "& .MuiStack-root": {
      display: "flex",
      alignItems: "center",
      lineHeight: "24px",
      justifyContent: "center",
      height: "100%",
      "& a": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        color: "#434343",
        fontSize: 16,
        whiteSpace: "nowrap",
        textDecoration: "none",
        "&:hover": {
          fontWeight: 700,
          color: "#000"
        },
        "&.active": {
          fontWeight: 700,
          color: "#000"
        }
      }
    }
  }} data-sentry-element="Stack" data-sentry-component="Menu" data-sentry-source-file="index.tsx">
      {menuConfigList.map((item, index) => <WrapperLinkHover key={`${item.title}-${index}`} title={item.title}>
          {item?.subMenu ? <MenuWithSubItem key={index} data={item} currentPath={currentPath} onRedirect={onRedirect} /> : <Link key={index} className={getClassNameActive(item, currentPath)} href={item.path} style={{
        color: item.color
      }}>
              {item.title}
              </Link>}
        </WrapperLinkHover>)}
    </Stack>;
};
export default Menu;